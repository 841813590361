/* 约标 */
.list {
  background: #fff;
  padding: 0 40px;


}

.listMake {
  .ant-tabs-nav {
    display: none;
  }
  .col{
    margin-bottom: 15px;
    i{
      margin-right: 8px;
      vertical-align: middle;
      color: red;
    }
    .label{
      text-align: left;
      font-weight: bold;
      line-height: 32px;
    }
    input {
      display: inline-block;
      line-height: 30px;
      width: 366px;
    }
  }
  .colRight{
    text-align: right;
    //label{
    //  text-align: left;
    //}
  }
  :global {
    .ant-tabs-nav {
      display: none !important;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;