/* 开标 */
.listConfirm {
  padding:0 60px 20px 60px;
  background: #fff;

  :global {
    .ant-tabs-nav {
      display: none !important;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;