/* 评标时间 */
.commendTime {
  background: #fff;
  .boxTitle{
    padding:12px 0 12px 14px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    color:#000;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &:before{
      position:absolute;
      top:50%;
      left: 0;
      content: '';
      width: 4px;
      height: 18px;
      background:#0f8605;
      margin-top: -8px;
    }
    .info{
      position: absolute;
      right: 0;
      font-size:14px;
      color: #666;
      font-weight: normal;
      .high{
        display: inline-block;
        margin-right: 30px;
        color: #333;
        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  .btns{
    display: flex;
    justify-content: space-around;
    margin-top: 34px;
    padding: 0 70px;
    button{
      width: 210px;
    }
  }
  .yellowBtn{
    background: #EFC740;
    color: #fff;
    margin: 0 20px;
    &:hover{
      color: #fff;
      border-color: #EFC740;
      background: #EFC740;

    }
  }
  .editor{
    border: 1px solid #ddd;
  }
  :global {
    .ant-tabs-nav {
      display: none !important;
    }
    .ant-descriptions-item-content{
      color: #999;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;