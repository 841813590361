.navClass{
  display: flex;
  line-height: 44px;
  font-size: 14px;
  color:#010101;

  cursor: pointer;
  margin-top:10px;
  >div{
    flex:1;
    padding-left: 40px;
  }
}
.navList{
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 24px 30px 5px;
  background: #fff;
  .nav{
    flex:1;
    position: relative;
    //border-bottom: 1px solid #E5E5E5;
    background:#fff;
    .navClass;
    //&:after{
    //    position:absolute;
    //    content: '';
    //    top:50%;
    //    left: 80px;
    //    margin-top: -8px;
    //    width:0px;
    //    height:0px;
    //    border-top:8px solid transparent;
    //    border-right:0px solid #666;
    //    border-bottom:8px solid transparent;
    //    border-left:8px solid #666;
    //}
    &.selected,&:hover{
      font-weight: bold;
      background: #5AB2A2;

      color: #fff;

      border-radius: 10px;
      //border-left:4px solid #0cad33;
      //&:after{
      //  border-right :8px solid transparent;
      //  border-top:8px solid #666;
      //  border-left:8px solid transparent;
      //  border-bottom : 0px solid #666;
      //  margin-top: -4px;
      //}
    }

  }
  .parentNav{
    background: #fff;
    //border-bottom:1px solid #f2f4f6;
    .navClass;

    &.selected,&:hover{
      position: relative;
      font-weight: bold;
      background: #5AB2A2;
      color: #fff;
      border-radius: 10px;
      //border-left:4px solid #0cad33;
      //&:after{
      //  border-right :8px solid transparent;
      //  border-top:8px solid #666;
      //  border-left:8px solid transparent;
      //  border-bottom : 0px solid #666;
      //  margin-top: -4px;
      //}
    }
    &.selected:after{
      content: '';
      position: absolute;
      right: -48px;
      top: 10px;
      height: 20px;
      width: 4px;
      border-radius: 2px;
      background: #5AB2A2;
    }
  }
  .childrenNav{
    .navClass;

    font-size: 14px;
    text-align: center;
    background: #e6f7ff;
    //border-bottom:1px solid #f2f4f6;
    padding-left: 30px;

    &.selected , &:hover{
      font-weight: bold;
      background: #e6f7ff;
      color: #1890ff;
      //border-left:4px solid #0cad33;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;