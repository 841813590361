/* 开标 */
.listOpen {
  padding:0 60px 20px 60px;
  background: #fff;
  .btns{
    display: flex;
    justify-content: space-around;
    margin-top: 34px;
    padding: 0 70px;
    button{
      width: 210px;
    }
  }
  :global {
    .tabs>.ant-tabs-nav {
       display: none !important;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;